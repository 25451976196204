.register-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 10px;
    text-align: center;

    h1 {
        font-size: 2.5rem;
        color: #007bff;
        margin-bottom: 20px;
    }

    p {
        font-size: 1.2rem;
        color: #666;
        margin-bottom: 30px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .form-group {
            width: 100%;
            margin-bottom: 20px;
            text-align: left;

            label {
                display: block;
                font-weight: bold;
                margin-bottom: 5px;
            }

            input,
            textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #ddd;
                border-radius: 5px;
                font-size: 1rem;
                transition: border-color 0.3s;

                &:focus {
                    border-color: #007bff;
                }
            }
        }

        .submit-button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
