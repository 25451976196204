.tester-container {
    padding: 50px 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    color: #333;

    .intro-section, .how-to-participate-section, .benefits-section {
        margin-bottom: 40px;

        h1, h2 {
            color: #007bff;
            margin-bottom: 20px;
        }

        p, ol, .benefits-list {
            font-size: 1.1rem;
            line-height: 1.6;
            color: #666;
        }

        ol {
            padding-left: 20px;

            li {
                margin-bottom: 10px;
            }
        }

        .cta-button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .benefits-section {
        .benefits-list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .benefit {
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                flex: 1;
                min-width: 200px;
                text-align: center;

                h3 {
                    color: #007bff;
                    margin-bottom: 10px;
                }

                p {
                    color: #666;
                }
            }
        }
    }
}
