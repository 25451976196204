.about-container {

    padding: 20px;
    background-color: #f5f5f5;
    color: #333;

    .intro-section,
    .history-section,
    .mission-section,
    .values-section,
    .team-section {
        margin-bottom: 40px;
    }

    h1, h2 {
        color: #007bff;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        font-size: 1.1rem;
        line-height: 1.6;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin-bottom: 10px;
            font-size: 1.1rem;
            line-height: 1.6;

            strong {
                color: #007bff;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 10px;

        h1 {
            font-size: 2rem;
        }

        h2 {
            font-size: 1.5rem;
        }

        p, li {
            font-size: 1rem;
        }
    }
}
