.home-container {
    .hero-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 20px;
        background-color: #f5f5f5;
        text-align: left;
        color: #333;

        .content {
            flex-basis: 50%;

            h1 {
                font-size: 2.5rem;
                margin-bottom: 20px;
            }

            p {
                font-size: 1.2rem;
                margin-bottom: 30px;
            }

            .cta-button {
                padding: 10px 20px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s, transform 0.3s;

                &:hover {
                    background-color: #0056b3;
                    transform: translateY(-5px);
                }
            }
        }

        .hero-image {
            flex-basis: 45%;
            img {
                width: 100%;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            }
        }
    }

    .features-section {
        padding: 50px 20px;
        background-color: #fff;
        text-align: center;

        h2 {
            font-size: 2rem;
            color: #333;
            margin-bottom: 30px;
        }

        .features {
            display: flex;
            flex-direction: column; // Stack features vertically on mobile
            justify-content: center;
            align-items: center;

            @media (min-width: 768px) {
                flex-direction: row; // Horizontal layout on larger screens
                flex-wrap: wrap;
                justify-content: space-around;
            }

            .feature {
                width: 90%; // Full width on mobile
                margin-bottom: 20px;
                background-color: #f9f9f9;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                cursor: pointer;
                transition: transform 0.3s, box-shadow 0.3s;

                @media (min-width: 768px) {
                    width: 30%; // Adjust width for larger screens
                }

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
                }

                .feature-icon {
                    font-size: 50px;
                    color: #007bff;
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                    color: #0056b3;
                }

                p {
                    font-size: 1rem;
                    color: #666;
                }

                .feature-details {
                    margin-top: 10px;
                    background-color: #e9ecef;
                    padding: 10px;
                    border-radius: 5px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }
            }
        }
    }

    .benefits-section {
        padding: 50px 20px;
        background-color: #f5f5f5;
        text-align: center;

        h2 {
            font-size: 2rem;
            color: #333;
            margin-bottom: 30px;
        }

        .benefits {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 768px) {
                flex-direction: row; // Horizontal layout on larger screens
                justify-content: space-around;
                flex-wrap: wrap;
            }

            .benefit {
                width: 90%; // Full width on mobile
                margin-bottom: 20px;
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                transition: transform 0.3s, box-shadow 0.3s;

                @media (min-width: 768px) {
                    width: 30%; // Adjust width for larger screens
                }

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
                }

                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                    color: #007bff;
                }

                p {
                    font-size: 1rem;
                    color: #666;
                }
            }
        }
    }

    .call-to-action-section {
        background-color: #007bff;
        color: white;
        text-align: center;
        padding: 50px 20px;

        h2 {
            font-size: 2rem;
            margin-bottom: 20px;
        }

        .cta-button {
            padding: 15px 30px;
            background-color: white;
            color: #007bff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s;

            &:hover {
                background-color: #f8f8f8;
                transform: translateY(-5px);
            }
        }
    }
}