.overview-container {
    padding: 50px 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    color: #333;

    .intro-section, .features-section, .cta-section {
        margin-bottom: 40px;

        h1, h2 {
            color: #007bff;
            margin-bottom: 20px;
        }

        p {
            font-size: 1.1rem;
            line-height: 1.6;
            color: #666;
            strong {
                color: #333;
            }
        }

        .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.1);
            margin-bottom: 20px;
            text-align: center;

            .feature-icon {
                font-size: 60px;
                color: #007bff;
                margin-bottom: 15px;
            }

            h3 {
                color: #007bff;
                margin-bottom: 10px;
            }

            p {
                color: #666;
                strong {
                    color: #333;
                }
            }
        }
    }

    .cta-section {
        text-align: center;

        h2 {
            color: #007bff;
            margin-bottom: 20px;
        }

        p{
            margin-bottom: 20px;
        }
        button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
