.top-bar {
    position: fixed;
    top: 0;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000;

    .logo a {
        text-decoration: none;
        color: #333;
        font-weight: bold;
        font-size: 2rem;
    }

    .menu {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;

        a {
            text-decoration: none; // Remove underline from all links
            color: inherit; // Inherit color from parent
            display: flex;
            align-items: center;
            width: 100%;
        }

        .menu-title {
            font-weight: bold;
            color: #333;
            display: flex;
            align-items: center;
        }

        .menu-icon {
            margin-left: 8px;
            color: #666;
            transition: color 0.3s ease;

            &.active {
                color: red;
            }
        }

        ul {
            list-style: none;
        }

        li {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            cursor: pointer;
            position: relative;
            transition: background-color 0.3s;

            &:hover {
                background-color: #f8f8f8;
            }

            .submenu {
                position: absolute;
                background-color: white;
                box-shadow: 0 8px 16px rgba(0,0,0,0.2);
                list-style: none;
                padding: 0;
                top: 100%;
                left: 0;
                width: max-content;
                z-index: 100;

                ul {
                    list-style: none;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    white-space: nowrap;

                    &:hover {
                        background-color: #e0e0e0;
                    }

                    .sub-submenu {
                        position: absolute;
                        left: 100%;
                        top: 0;
                        background-color: #fff;

                        ul {
                            list-style: none;
                        }

                        li {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding: 5px 10px;

                            &:hover {
                                background-color: #d0d0d0;
                            }

                            .menu-description {
                                color: #666;
                                font-size: 0.8rem;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-right: 50px;
        button {
            padding: 10px 25px;
       
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

}
.main-content {
    padding-top: 100px; // Default padding for larger screens

    @media (max-width: 768px) {
        padding-top: 60px; // Adjust padding for mobile screens
    }
}