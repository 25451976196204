.top-bar-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .logo {
        font-size: 2rem;
        font-weight: bold;
        color: #333;

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .mobile-menu-icon {
        margin-right: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobile-menu {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transition: transform 0.3s ease-in-out;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;

            li {
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid #eee;
                display: flex;
                align-items: center;

                .menu-icon {
                    margin-right: 10px;
                    color: #007bff;
                }

                a {
                    text-decoration: none;
                    color: #333;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    transition: color 0.3s ease-in-out;

                    &:hover {
                        color: #0056b3;
                    }
                }

                button {
                    width: 100%;
                    padding: 10px 0;
                    background: none;
                    border: none;
                    color: #333;
                    text-align: left;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &:hover {
                        color: #0056b3;
                    }
                }
            }
        }
    }
}
